@font-face {
  src: url(./assets/fonts/OpenSans-Regular.ttf);
  font-family: ColOpen;
}

body {
  margin: 0;
  font-family: ColOpen;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn:focus {
  box-shadow: none;
}
