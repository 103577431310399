@import '../theme';

$border-color: #c7c7c7;

.nav-link > .active > a {
  color: $primary-color !important;
}
.nav-link > a:hover {
  color: $primary-color !important;
}

.searchContainer {
  display: inline-flex;
  flex: 1 1 300px;
  border-radius: 5px;
  overflow: hidden;
}

.searchBox {
  padding: 10px 0px 10px 10px !important;
  border-right: 0px;
}

.css-tj5bde-Svg {
  fill: black !important;
}

.css-dee1xg-control {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.css-319lph-ValueContainer {
  padding: 4px 8px !important;
}
.searchBox:focus {
  border-right: 2px solid $primary-color !important;
  border: 2px solid $primary-color;
  box-shadow: none;
}

.nav-link {
  font-weight: bold;
  & > a {
    color: $primary-color !important;
    &:hover {
      color: $primary-color !important;
    }
  }
}

.col-user-dropdown {
  @mixin ColDropDownDefaultBehaviour {
    background-color: transparent;
    color: $table-header-font-color;
    border: none;
  }
  button {
    @include ColDropDownDefaultBehaviour();
    padding-top: 0;
    &:hover,
    &:focus {
      @include ColDropDownDefaultBehaviour();
    }
  }
  .show > .btn-primary.dropdown-toggle {
    @include ColDropDownDefaultBehaviour();
    &:focus {
      box-shadow: none;
    }
  }

  .btn:focus {
    box-shadow: none;
  }
}

.login-link-col-header {
  a {
    color: $nav-link-default-color !important;
    text-decoration: none;
  }
}

.desktop-search-bar {
  display: none;
  @media only screen and (min-width: 991px) {
    display: block !important;
  }
}

.col-navbar-brand {
  border: none;
}

.navbar-toggler:focus {
  border: none;
  box-shadow: none;
}

.navbar > .container-fluid {
  @media only screen and (max-width: 991px) {
    display: block !important;
  }
}

.col-logo-nav {
  @media only screen and (max-width: 504px) {
    object-fit: contain;
    height: 60%;
    width: 80%;
  }
}

.mobile-search-bar {
  display: none;
  border-top: 2px solid $border-color;
  .col-select-dropdown .col-select-dropdown__control {
    border-radius: 4px;
  }
  .mobile-ui-enable-search-fields {
    & > button {
      border: none;
      background-color: transparent;
      color: #000000;
    }
  }
  .search-field-mobile {
    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      border: 2px solid $primary-color;
    }
    border-radius: 4px;
    border: 1px solid $border-color;
    .form-control {
      border: none;
      &:focus {
        border: none !important;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    display: block !important;
  }
}

.navbar {
  .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0 !important;
  }

  .nav-search-top {
    border-radius: 4px;
    border: 1px solid $border-color;
    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      border: 2px solid $primary-color;
    }

    .col-select-dropdown .col-select-dropdown__control {
      border: none !important;
      width: 375px !important;
      position: relative;

      @media (max-width: 1197px) {
        width: 300px !important;
      }

      @media (max-width: 1122px) {
        width: 250px !important;
      }

      @media (max-width: 1072px) {
        width: 200px !important;
      }

      &::after {
        content: '';
        position: absolute;
        top: 10%;
        right: 0;
        width: 1px;
        background-color: $border-color;
        height: 80%;
        &:focus {
          display: none;
        }
      }
    }
    .col-form-input {
      display: block;
      border: none;
      position: relative;
      &:focus {
        border: none !important;
      }
    }
  }
}

.search-icon {
  z-index: 0;
  & > button.input-group-text {
    border: none;
  }
  .input-group-text {
    height: 42px;
    background: #ffffff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
}

.col-user-dropdown {
  .dropdown-menu {
    border: 2px solid $primary-color;
    left: -80px;
    .dropdown-item {
      color: #000000;
      &:hover {
        background-color: transparent;
        color: $primary-color !important;
        font-weight: bold;
      }
    }
  }
}
#col-collapse-navbar .dropdown-menu {
  width: 160px;
}

.faq-style {
  img {
    margin-bottom: 2px;
  }
  .btn-primary {
    background-color: transparent;
    border-color: transparent;
  }
}

.faq-modal {
  .modal-dialog {
    max-width: 90%;
    .modal-content {
      max-height: 90%;
      .modal-header {
        .btn-close {
          padding-right: 15px;
          background: url(../../assets/close.svg) no-repeat scroll 57% 8px;
        }
        color: $primary-color;
        font-weight: bold;
      }
      .modal-body {
        padding: 18px;
        overflow-y: auto;
        @media (max-width: 640px) and (orientation: landscape) {
          max-height: 275px;
        }
      }
      .accordion {
        .accordion-item {
          &:hover {
            border: 2px solid $primary-color;
          }
          box-shadow: none;
          border: 2px solid #d1d1d1;
          border-radius: 8px;
          .accordion-header {
            .accordion-button::after {
              background: url(../../assets/faq_dropdown_inactive.svg) no-repeat
                40% 0;
              transform: rotate(270deg);
              width: 2rem;
              height: 2rem;
            }
            .accordion-button:not(.collapsed)::after {
              background: url(../../assets/faq_dropdown_active.svg) no-repeat;
              transform: rotate(360deg);
            }
            .accordion-button {
              border-radius: 8px;
              box-shadow: none;
              padding: 5px 5px 5px 15px;
            }
            .accordion-button:not(.collapsed) {
              .question-style {
                border-bottom: 1px solid #d1d1d1;
                width: 100%;
                padding-bottom: 10px;
                padding-top: 6px;
              }
            }
          }
          .accordion-button:not(.collapsed) {
            box-shadow: none;
            color: $primary-color;
            font-weight: bold;
            background-color: transparent;
          }
        }
      }
    }
  }
}

.faq-style {
  display: block;
}

.set-col-input-focus {
  border: 2px solid #463688 !important;
}

.modal.show {
  overflow-y: hidden !important;
}

.mobile-search-style {
  font-weight: bold;
}
