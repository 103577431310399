@import '../theme';

$bg: #d9d4c1;
$border-color: #aab2ab;

.CCBYSA {
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-decoration-none {
  color: inherit;
}

footer {
  background-color: $bg;
}
.heading-style {
  font-weight: bold;
  color: $primary-color;
  font-size: 16px;
}

.listgroupbg {
  background: rgba($bg, 0) !important;
  color: black;
  padding-bottom: 0px;
}

$border-width: 1px;

.footer-divider {
  border-right: $border-width solid $border-color;
}
.long-divider {
  border-right: $border-width solid $border-color;
}

.group6 {
  width: 150px;
  height: 130px;
}

.paclogo {
  width: 370px;
  height: 100px;
}

.ccbysa {
  width: 185px;
  height: 66px;
}

@media screen and (max-width: 991px) {
  .long-divider {
    border-right: 0;
    border-bottom: $border-width solid $border-color;
  }
  .listgroupbg {
    text-align: center;
  }
}

@media screen and (max-width: 575px) {
  .footer-divider {
    border-right: 0;
    border-bottom: $border-width solid $border-color;
  }
}
