@import './component/MediaQuery.scss';

// Colors
$primary-color: #463688;
$text-color: #ffffff;
$admin-background-color: #f0f0f0;
$site-background-color: $text-color;
$table-header-bg: #d1d1d1;
$table-header-font-color: #000000;
$col-form-label-color: #000000;
$nav-link-default-color: #6e6f6e;
$table-inactive-button-color: #6e6f6e;
$text-color-black: #000000;
$text-color-white: #ffffff;
$banner-bg-color: #f4f4f4;
$carousel-bg-color: #9f915dc0;

// fonts
$table-header-font-size: 16px;
$table-body-font-size: 16px;
$base-text-size: 20px;
$table-active-button-color: #00946d;

@mixin col-form-back-arrow {
  color: $primary-color;
  font-weight: 800;
}

@mixin col-form-title {
  color: $primary-color;
  font-size: 20px;
  font-weight: 900;
  margin-top: 5px;
}

// Label Style ---- Start
@mixin col-form-label {
  color: $col-form-label-color;
  font-size: 16px;
  width: 100%;
  height: 42px;
  cursor: pointer;
}
@mixin col-label-focus-style {
  color: $primary-color;
  font-size: 16px;
  font-weight: bold;
}

// Input Style
@mixin col-form-input {
  &:focus {
    border: 2px solid $primary-color;
    box-shadow: none;
  }
  &:hover {
    color: $col-form-label-color;
  }
  height: 42px;
  @include br-and-bg();
}

// Text Area Style
@mixin col-form-text-area {
  &:focus {
    border: 2px solid $primary-color;
    box-shadow: none;
  }
  &:hover {
    color: $col-form-label-color;
  }
  height: 115px;
  @include br-and-bg();
}

// Check Box Style
@mixin col-form-check {
  border: 1px solid #d1d1d1;
  border-radius: 2px !important;
  background: $text-color;
}

@mixin col-form-check-label {
  color: #000000;
}

// Select Style
@mixin select-field-42 {
  & svg {
    color: #6e6f6e;
  }
  height: 42px;
  cursor: pointer;
}

// Submit Button Style
@mixin col-submit-btn {
  &:hover {
    background: $primary-color !important;
    border-color: $primary-color !important;
  }
  min-width: 154px;
  height: 42px;
  border-radius: 4px;
  color: $text-color;
  background: $primary-color;
  opacity: 1;
  font-weight: bold;
  border: none;
}

// Submit btn disabled state style
@mixin col-submit-btn-disabled-style {
  color: $text-color;
  background-color: $primary-color;
}

@mixin submit-btn-focus {
  color: $text-color;
  background-color: $primary-color;
  border-color: $primary-color;
  box-shadow: none;
}

@mixin br-and-bg {
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  background: $text-color;
  padding: 10px 35px 10px 10px;
}

// Select Style
@mixin col-select-pipe {
  background-color: #ffffff !important;
}

@mixin check-box-shadow-none {
  &:focus {
    box-shadow: none;
  }
  &:checked {
    background-color: #463688;
  }
  cursor: pointer;
}

@mixin description-count-style {
  font-size: 12px;
}

@mixin col-back-button {
  background-color: transparent;
  border: none;
  padding-left: 0;
  &:hover,
  &:focus {
    background-color: transparent;
    border: none;
  }
}

@mixin col-form-x-y-padding {
  padding: 0 35px 0 35px;
}

.col-primary-color {
  color: $primary-color;
}

.col-button-primary {
  background-color: $primary-color;
  color: $text-color;
  font-weight: bold;
  border: none;
  &:hover,
  &:visited,
  &:focus,
  &:active {
    background-color: $primary-color;
    border: none;
    box-shadow: none;
  }
}

.col-base-font {
  font-size: $base-text-size;
}

.col-admin-bg {
  background-color: $admin-background-color;
  min-height: 90vh;
}

.col-site-bg {
  background-color: $site-background-color;
  min-height: 90vh;
}

.col-table-active-button {
  background-color: $table-active-button-color !important;
  border: none;
}
.btn-primary:active:focus {
  box-shadow: none;
}
.col-table-inactive-button {
  background-color: $table-inactive-button-color !important;
  border: none;
}

.col-admin-table-icon {
  cursor: pointer;
  background-color: transparent;
  border: none;
  &:focus {
    background-color: transparent;
    border: none;
  }
  &:hover {
    background-color: transparent;
    border: none;
  }
}

// safari browser btn outline color issue fixed
btn:focus,
a:focus {
  outline: none;
}

.admin-table-style {
  padding: 0 36px 0 36px;
}

.password-block {
  border-radius: 5px !important;
}
@mixin btn-primary {
  color: $text-color-white;
  background-color: $primary-color;
  border-color: $primary-color;
}

@mixin banner-button {
  @include btn-primary();
  font-weight: bold;
  border-radius: 4px;
}

@mixin banner-caption {
  width: 52%;
  min-height: 23vw;
  background: $carousel-bg-color;
  opacity: 0.9;
  border-radius: 0px 12px 12px 0px;
}
