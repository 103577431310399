@import '../theme';

$border-color: #696969;

.course-img-thumbnail {
  object-fit: cover;
  height: 300px;
  max-height: 300px;
  border-color: transparent;
}

hr.course-detailed-page-divider {
  border-top: 2px solid $border-color;
}

.home-back-button {
  background-color: transparent;
  border: none;
  padding-left: 0px;
  margin-bottom: 5px;
}
.home-back-button:active,
.home-back-button:focus,
.home-back-button:hover {
  background: none;
}

.detailed-card-body {
  background-color: $primary-color;
  margin-top: 3px;
  padding-bottom: 35px;
}

.detailed-card-text {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.detailed-card-text-title {
  font-weight: bold;
  color: #ffffff;
  font-size: 20px;
}

.description-heading {
  color: $primary-color;
}
.description-submit {
  color: $primary-color !important;
  font-weight: bolder;
  background-color: white !important;
  border: none;
}

.email-facilator {
  text-decoration: none;
  color: white;
}
.mail-icon {
  width: 30px;
  height: 30px;
  padding: 0px 5px 0px 5px;
  margin-bottom: 3px;
  filter: brightness(0) invert(1);
}

// The UI for this page might be change after the discussion with siva, So for now the media queries were placed here,
// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .description-card-style {
    padding: 0px 20px 0px 20px;
    width: 100%;
    border: none;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .description-card-style {
    padding: 0px 20px 0px 20px;
    width: 80%;
    border: none;
    margin-left: auto;
    margin-right: auto;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .description-card-style {
    padding: 0px 20px 0px 20px;
    width: 100%;
    border: none;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1900px) {
  .description-card-style {
    padding: 0px 20px 0px 20px;
    width: 70%;
    border: none;
    margin-left: auto;
    margin-right: auto;
  }
}
