@import '../theme';

.add-course-back-button {
  @include col-back-button;
}

// Back Arrow Style
.add-course-form {
  @include col-form-x-y-padding;
  .form-title {
    @include col-form-title;
  }

  .multi-select:focus-within .col-form-label {
    @include col-label-focus-style;
  }

  .col-form-label {
    @include col-form-label;
  }
  .col-form-input {
    @include col-form-input;
  }
  .col-form-textarea {
    @include col-form-text-area();
  }
  .form-check-input {
    @include col-form-check();
  }
  .form-check-label {
    @include col-form-check-label();
  }
  .col-submit-btn {
    &:disabled {
      @include col-submit-btn-disabled-style();
    }
    @include col-submit-btn();
  }
  .col-submit-btn.btn-primary:focus {
    @include submit-btn-focus();
  }

  .col-select-field__control {
    @include select-field-42;
  }
  .col-label-focus:focus-within label {
    @include col-label-focus-style;
  }
  .col-select-field__control .col-select-field__indicator-separator {
    @include col-select-pipe;
  }
  .basic-multi-select__control .basic-multi-select__indicator-separator {
    @include col-select-pipe;
  }
  .check-box-shadow-none .form-check-input {
    @include check-box-shadow-none();
  }

  .image-style {
    position: relative;
    border-radius: 5px !important;
    @include br-and-bg();
    height: 42px;
    padding: 0 !important;
  }

  .password-eye:active:focus {
    box-shadow: none;
  }

  .image-icon-style {
    position: absolute;
    right: 10px;
    padding: 6px;
    cursor: pointer;
  }
  .desc-count-style {
    @include description-count-style();
  }

  .col-date-style {
    position: relative;
  }
  .image-field-padding {
    padding-left: 10px;
    padding-right: 50px;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    background: url(../../assets/upload.svg) no-repeat scroll 97% 7px;
    line-height: 1.9;
  }

  .img-icon-style {
    border: 1px solid #d1d1d1;
    align-items: center;
    padding-right: 20px;
  }
  .image-icon {
    &:focus {
      border: 2px solid $primary-color;
    }
  }
  .col-date-style .input-group {
    flex-wrap: nowrap;
  }

  .react-datepicker-wrapper
    .react-datepicker__input-container
    .react-datepicker-ignore-onclickoutside:focus-visible {
    outline: none;
  }
  .col-start-date-field,
  .col-end-date-field {
    cursor: pointer;
    background: white url(../../assets/calendar.svg) no-repeat scroll 93% 7px;
  }
  .input-group-text {
    background: $text-color;
    border-left: 0;
  }
  .image-size-desc {
    font-size: 13px;
  }
  .right-side-content-style {
    position: relative;

    .content-style {
      position: absolute;
      right: 11px;
      top: 10px;
    }
  }
  .default-img-label {
    text-decoration-line: underline;
    font-size: 12px;
    font-weight: bold;
    color: #1a73e8;
    cursor: pointer;
  }

  .react-datepicker {
    border: 2px solid $primary-color;
    .react-datepicker__navigation-icon::before {
      border-color: $primary-color;
    }
    .react-datepicker__day--selected {
      color: $text-color;
      background: $primary-color;
      border: 2px solid $primary-color;
    }
  }
  .cursor-not-allowed {
    cursor: not-allowed;
  }
  .default-image-style {
    .form-check-input {
      margin-top: 6px;
    }
  }
  .basic-multi-select__control {
    cursor: pointer;
  }
  .col-select-field__placeholder {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-danger {
    font-size: 14px;
  }
  .multi-select .form-check-input:checked {
    background-color: $primary-color;
  }
}

.default-image {
  height: 300px;
  overflow: hidden;
}

.default-image-style {
  color: $primary-color;
  font-weight: bold;
}
.btn-close:focus {
  box-shadow: none;
}
.btn-close {
  background-repeat: no-repeat;
  padding: 0.5rem 1.5rem 0.1rem 0.2rem;
}
.modal-content {
  border: 2px solid $primary-color;
  border-radius: 6px;
}

.button-loader {
  margin-right: 5px;
}

.preview-stle {
  height: 381px;
  .default-image-style {
    height: 381px;
    color: $primary-color;
  }
  .modal-header {
    font-size: 24px;
    color: $primary-color;
  }
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: $primary-color;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  top: -1px !important;
  border-bottom-color: $primary-color !important;
}
.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::after {
  bottom: -1px !important;
  border-bottom: none !important;
  border-top-color: $primary-color !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.admin-table > div > div {
  display: block;
}
