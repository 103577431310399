@import '../theme';

$dropdown-input-color: hsl(252, 43%, 37%);
$border-width: 2px;
$border-input-normal-color: #c7c7c7;

@mixin fontBackgroundStyle {
  background-color: transparent !important;
  font-weight: bold;
}

.col-select-dropdown .col-select-dropdown__control {
  width: 100% !important;
  border-radius: 5px;
  cursor: pointer;
  height: 42px;
  box-shadow: none;
  border: 1px solid #d1d1d1;
}

.col-select-dropdown__control:hover {
  border-color: #d1d1d1 !important;
}

.col-select-dropdown__indicator-separator {
  display: none !important;
}

.col-select-dropdown__menu {
  border: 2px solid $primary-color;
  & > .col-select-dropdown__menu-list {
    & > div {
      &:hover {
        color: #212529;
        @include fontBackgroundStyle();
      }
    }
  }
}

.col-select-dropdown__option {
  background-color: transparent !important;
}

.col-select-dropdown .col-select-dropdown__menu {
  box-shadow: none;
}

.col-select-dropdown__menu-list .col-select-dropdown__option--is-selected {
  color: $primary-color;
  font-weight: bold;
}

.col-select-dropdown__menu-list:hover {
  .col-select-dropdown__option--is-focused {
    color: #212529;
    @include fontBackgroundStyle();
  }
}

.col-select-dropdown__menu-list {
  div > .col-select-dropdown__option--is-selected .form-check-label {
    color: $primary-color;
    @include fontBackgroundStyle();
  }
}

.col-select-dropdown__menu-list div {
  &.col-select-dropdown__option,
  input,
  label {
    cursor: pointer;
  }
}

.multi-select,
.col-select-dropdown {
  .col-select-dropdown__control--menu-is-open {
    border: 2px solid $primary-color !important;
    box-shadow: none;
  }
}
