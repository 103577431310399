@import '../theme';
@import './MediaQuery.scss';

.col-label-focus:focus-within label {
  @include col-label-focus-style;
}
.col-form-label {
  @include col-form-label;
}
.col-form-title {
  @include col-form-title;
}
.col-form-input {
  @include col-form-input;
}
.login-page {
  background-image: url('../../assets/fonts//login_image/login_image.png');
  background-size: cover;
  position: relative;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  min-height: 650px;
  padding-right: 10rem;
  // justify-content: end;
}
.border-style {
  border: 2px solid $primary-color;
  border-radius: 13px;
  background: $text-color 0% 0% no-repeat padding-box;
  float: right;
  width: 25%;
}
.login-head {
  color: $primary-color;
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
}
.login-button {
  background-color: $primary-color !important;
  border: none;
}
.image-style {
  position: relative;
  border-radius: 5px !important;
}
//ms-edge browser password icon custom hide
::-ms-reveal {
  display: none;
}
::-ms-reveal {
  border: 1px solid transparent;
  border-radius: 50%;
  box-shadow: 0 0 3px;
}
//safari browser password icon custom hide
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
.image-style .col-form-input {
  padding-right: 45px;
}
.eye-icon {
  position: absolute;
  right: 2px;
  top: 1px;
  background: transparent !important;
  border: none;
}
.eye-icon.btn-primary:focus {
  box-shadow: none;
}
.forget-password {
  font-size: 10px;
}

.forgotPassword {
  .col-submit-btn {
    &:disabled {
      @include col-submit-btn-disabled-style();
    }
    @include col-submit-btn();
  }
}

.forgotPassword {
  .cursor-not-allowed {
    cursor: not-allowed;
  }
  .text-danger {
    font-size: 14px;
  }
  .security-code-style {
    position: relative;
    .success {
      background: url(../../assets/code_success.svg) no-repeat scroll 97% 9px;
    }
    .spinner-border {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      border: 0.15em solid currentColor;
      border-right-color: transparent;
      right: 10px;
      top: calc(50% - 12px)
    }
  }
}

