$primary-color: #463688;
$text-color-black: #000000;
$text-color-white: #ffffff;
$indicator-inactive-color: #6e6f6e;

@media (min-width: 360px) {
  .faq-modal .modal-dialog {
    margin: 1.75rem auto;
  }
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(1 * 1.5rem);
  }
}

@media (min-width: 360px) and (orientation: landscape) {
  .faq-modal .modal-dialog {
    margin: 1.75rem auto;
  }
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(0.9 * 1.5rem);
  }
}

@media (min-width: 412px) {
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(2.2 * 1.5rem);
  }
}

@media (min-width: 412px) and (orientation: landscape) {
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(3.6 * 1.5rem);
  }
}

@media (min-width: 601px) {
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(4.9 * 1.5rem);
  }
}

@media (min-width: 601px) and (orientation: landscape) {
  .faq-modal .modal-dialog {
    margin: 1.75rem auto;
  }
}

@media (min-width: 640px) {
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(0.8 * 1.5rem);
  }
  .container {
    max-width: 639px;
  }
}

@media (min-width: 768px) {
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(2.7 * 1.5rem);
  }
  .container {
    max-width: 720px;
  }
}

@media (min-width: 767px) and (orientation: landscape) {
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(1.8 * 1.5rem);
  }
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 892px) {
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(2.5 * 1.5rem);
  }
}

@media (min-width: 892px) and (orientation: landscape) {
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(6 * 1.5rem);
  }
}

@media (min-width: 962px) and (orientation: landscape) {
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(1.6 * 1.5rem);
  }
}

@media (max-width: 990px) {
  .faq-style {
    display: none !important;
  }
  .faq-modal .modal-dialog .modal-content {
    max-height: 115vw;
  }
  .container {
    max-width: 990px;
  }
  .container.tile-view {
    max-width: 1350px;
  }
}

@media (min-width: 992px) {
  .site .navbar-collapse {
    display: block !important;
  }
  .main .navbar-collapse {
    display: block;
  }
}

@media (min-width: 1024px) and (orientation: landscape) {
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(2.8 * 1.5rem);
  }
  .col-site-bg .container .course-top-bar {
    padding: 1.1rem 0.2rem 1.1rem 0.7rem;
  }
  .col-site-bg .container.tile-view .course-top-bar {
    padding: 1.1rem 6.9rem 1.1rem 6.9rem;
  }
}

@media only screen and (max-width: 1024px) {
  .catalog-table > div > div,
  .admin-table > div > div {
    display: table;
  }
  .admin-table .faq-modal .modal-dialog .modal-content {
    max-height: 100vw;
  }
  .catalog-popover-style {
    display: none;
  }
  .web-banner {
    display: none;
  }
  .mobile-banner {
    display: block;
  }
}

@media (min-width: 1079px) {
  .web-banner .last-slide-style .pt-3 {
    padding-top: 1vw !important;
  }
  .web-banner .carousel-indicators {
    margin-bottom: 5rem;
  }
}

@media (min-width: 1279px) {
  .web-banner .carousel-indicators {
    margin-bottom: 4rem;
  }
}

@media (min-width: 1366px) {
  .col-date-style.col-lg-3 {
    width: 20% !important;
  }
  .web-banner .carousel-indicators {
    margin-bottom: 5rem;
  }
  .col-site-bg .container .course-top-bar {
    padding: 1.1rem 0.2rem 1.1rem 0.7rem;
  }
  .col-site-bg .container.tile-view .course-top-bar {
    padding: 1.1rem 8rem 1.1rem 8.3rem;
  }
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(3.7 * 1.5rem);
  }
  .container {
    max-width: 1332px;
  }
}

@media (min-width: 1400px) {
  .col-date-style.col-lg-3 {
    width: 20% !important;
  }
  .web-banner .carousel-caption .p-tag-style {
    margin-bottom: 3.2vw;
  }
  .container {
    max-width: 1552px;
  }
  .container.tile-view {
    max-width: 1590px;
  }
  .col-site-bg .tile-view .course-top-bar {
    padding: 16px 38px 16px 38px;
  }
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(2.7 * 1.5rem);
  }
}

@media (min-width: 1920px) {
  .col-date-style.col-lg-3 {
    width: 20% !important;
  }
  .web-banner .carousel-caption .p-tag-style {
    margin-bottom: 2.2vw;
  }
  .container {
    max-width: 1552px;
  }
  .container.tile-view {
    max-width: 1590px;
  }
  .col-site-bg .container .course-top-bar {
    padding: 1.1rem 0.2rem 1.1rem 0.8rem;
  }
  .col-site-bg .container.tile-view .course-top-bar {
    padding: 1.1rem 6.9rem 1.1rem 6.3rem;
  }
  .col-site-bg .tile-view .base-card-style.row {
    margin-left: calc(2.5 * 1.5rem);
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) {
  .border-style {
    width: auto;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 420px) {
  .col-select-dropdown .col-select-dropdown__control {
    height: auto !important;
  }
}

@media only screen and (min-device-width: 570px) and (max-device-width: 690px) {
  .col-select-dropdown .col-select-dropdown__control {
    height: auto !important;
  }
}

@media only screen and (min-device-width: 980px) and (max-device-width: 1330px) {
  .col-select-dropdown .col-select-dropdown__control {
    height: auto !important;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .border-style {
    width: 100%;
    float: none;
  }
  .login-page {
    padding-right: 0px;
  }
}

@media only screen and (max-device-width: 1536px) {
  .border-style {
    width: 35%;
  }
}

@media only screen and (max-device-width: 684px) {
  .border-style {
    width: auto;
  }
}

@media only screen and (max-device-width: 896px) {
  .border-style {
    width: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .login-page {
    min-height: 68vh;
  }
}
@media only screen and (min-device-width: 1080px) and (max-device-width: 1920px) and (orientation: landscape) {
  .login-page {
    min-height: 71vh;
  }
}

@media only screen and (min-width: 992px) {
  .navbar-expand-lg .desktop-search-bar {
    margin-left: 18% !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: landscape) {
  .navbar-expand-lg .desktop-search-bar {
    margin-left: 5% !important;
  }
}

@media only screen and(max-width: 991px) {
  .course-top-bar {
    flex-direction: column-reverse;
  }
}

.mobileHide {
  display: block;
  @media (max-width: 412px) and (orientation: portrait) {
    display: none;
  }

  @media (max-width: 640px) and (orientation: landscape) {
    display: none;
  }

  @media (max-width: 892px) and (orientation: landscape) {
    display: none;
  }
  .mobile-show {
    display: none;
  }
}

.mobile-show {
  display: none;
  @media (max-width: 412px) and (orientation: portrait) {
    display: block;
  }

  @media (max-width: 640px) and (orientation: landscape) {
    display: block;
  }

  @media (max-width: 892px) and (orientation: landscape) {
    display: block;
  }
}

@media only screen and(max-width: 991px) {
  .col-site-bg {
    .container .course-top-bar {
      padding: 16px 13px 2px 13px !important;
    }
  }
  .option {
    & > .icon {
      padding-bottom: 8px;
    }
  }
}
